<template>
  <v-card flat>
     <h3>{{title}}</h3>
    <v-card-text>
      <v-layout wrap>
        <v-flex v-for="permission in permissionSet" :key="permission.code">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-switch
                v-on="on"
                v-model="computedPermissions"
                :label="permission.name"
                :value="permission.code"
              ></v-switch>
            </template>
            <span>{{permission.description}}</span>
          </v-tooltip>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["title", "value", "permissionSet"],
  data() {
    return {
      selectedPermissions: []
    };
  },
  computed: {
    computedPermissions: {
      get() {
        this.selectedPermissions = this.value;
        return this.selectedPermissions;
      },
      set(newValue) {
        this.selectedPermissions = newValue;
        this.$emit("input", this.selectedPermissions);
      }
    }
  }
};
</script>
<style scoped>
</style>

<template>
<div>
    <v-card class="display-block" flat>
        <h3>Modify Role</h3>
        <v-card-text>
            <v-form ref="form" lazy-validation>
                <v-card flat>
                    <alert-message v-if="msg" :error="msg"></alert-message>
                    <v-card-text>
                        <v-text-field v-model.trim="role.name" :rules="nameRules" label="Role name* :" required></v-text-field>
                        <v-textarea v-model="role.description" label=" Description :" required></v-textarea>
                        <v-combobox v-model="role.level" :items="levels" label="Role level* " :disabled="id!=0" :rules="fieldRequired" required></v-combobox>
                        <v-flex v-if="level==1">
                            <template v-for="permission in Object.entries(permissions)">
                                <permission-card :key="permission[0]" :title="permission[0]" :permissionSet="permission[1]" v-model="role.permissions"></permission-card>
                            </template>
                        </v-flex>
                        <template v-for="permission in map">
                            <permission-card :key="permission[0].module" :title="permission[0].module" :permissionSet="permission" v-model="role.permissions"></permission-card>
                        </template>
                    </v-card-text>
                </v-card>
                <v-card flat>
                    <alert-message v-if="msg" :error="msg"></alert-message>
                    <v-flex text-right>
                        <v-btn class="mr-4" :style="theme" @click="validate">Submit</v-btn>
                        <v-btn class="mr-4" :style="themeInverted" @click="$router.go(-1)">Cancel</v-btn>
                    </v-flex>
                </v-card>
            </v-form>
        </v-card-text>
    </v-card>
</div>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
import permissionCard from "@/components/PermissionCard.vue";

export default {
    components: {
        permissionCard,
    },
    data: () => ({
        permissions: [],
        nameRules: [v => !!v || "Role name is required"],
        role: {
            name: '',
        },
        id: 0,
        levels: [],
        level: 0,
        map: {}
    }),

    mounted() {
        this.initialize();
        this.readPermissons();
    },
    methods: {
        async initialize() {
            let level = this.getUserProfile().level
            for (let i = 1; i < 5; i++)
                this.levels.push(level + i)
            this.id = this.$route.params.id;
            this.role = {
                name: "",
                description: "",
                permissions: [],
            };
            if (this.id != 0) {
                try {
                    await this.getItem(appConstants.ROLES_API + "/" + this.id).then(
                        (response) => {
                            this.role = response;
                        }
                    );
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
        validate() {
            if (this.$refs.form.validate()) {
                this.role.vendorId = this.getUserProfile().vendorId
                try {
                    if (this.id == 0) {
                        this.saveItem()
                    } else {
                        this.updateItem()
                    }
                } catch (error) {
                    this.handleError(error);
                }
            }
        },
        async saveItem() {
            try {
                this.role = await this.postItem(appConstants.ROLES_API, this.role)
                this.$router.push({
                    path: "/app/roles",
                });
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        async updateItem() {
            try {
                await this.putItem(appConstants.ROLES_API + "/" + this.id, this.role)
                this.$router.push({
                    path: "/app/roles",
                });
            } catch (error) {
                this.handleError(error)
                this.msg = error.response.data
            }
        },
        async readPermissons() {
            this.permissions = [];
            this.permissions = await this.getItem(appConstants.ROLES_API + "/permissions");
            let profilePermissions = this.getUserProfile().permissions
            this.level = this.getUserProfile().level
            if (this.level == 1)
                this.permission = this.items
            else {
                Object.entries(this.permissions).forEach(rec => {
                    rec[1].forEach(record => {
                        if (profilePermissions.indexOf(record.code) > -1) {
                            if (!this.map.hasOwnProperty(rec[0])) {
                                this.map[rec[0]] = [record]
                            } else {
                                this.map[rec[0]].push(record)
                            }
                        }
                    })
                })
            }
        },
    },
};
</script>
